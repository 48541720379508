import BlackFooter from "../../components/BlackFooter/BlackFooter";
import CaseStudyCard from "../../components/CaseStudyCard/CaseStudyCard";
import Navbar from "../../components/Navbar/Navbar";
import WhiteFooter from "../../components/WhiteFooter/WhiteFooter";
import "./style.css";

export const CaseStudy = () => {
    const data = [
        {
            title: "GoPreg Case Study",
            subtitle: "Your Holistic Pregnancy & Parenting Companion",
            pdf: "https://drive.google.com/file/d/143Tn5zwjTn6R7cyRj255M-qQfSybuYfq/preview",
        },
        {
            title: "Bhomio Case Study",
            subtitle: "Discover Authentic Travel",
            pdf: "https://drive.google.com/file/d/1hBqqOdtOEYhucyPSRK4--1MyUl-T9Cp8/preview",
        },
    ];

    return (
        <section className="case-study-pages-container">
            <h1 className="case-title-text">Case Studies</h1>
            <p className="case-subtitle-text">
            Check out some examples of our case studies:
            </p>

            <section className="case-study-container">
                {data.map((el, idx) => (
                    <CaseStudyCard key={idx} title={el.title} subtitle={el.subtitle} pdf={el.pdf}/>
                ))}
            </section>
            <WhiteFooter />
            <div className="case-study-footer">
                <BlackFooter />
            </div>
        </section>
    );
};

import React from 'react'

import "./CaseStudyCard.css"
import PDFModal from '../PdfModal/PdfModal';

const CaseStudyCard = (
    {
        title,
        subtitle,
        pdf
    }
) => {

    const [open, setOpen] = React.useState(false);

    const handleOpen = (imgSrc, imgAlt) => {
        setOpen(true);
      };
    
    const handleClose = () => setOpen(false);
    return (
        <div>
            <div className='case-study-card' onClick={handleOpen}>
                <div className='card-title-text'>{title}</div>
                <div className='card-center-line'></div>
                <div className='card-subtitle-text'>{subtitle}</div>
            </div>

            <PDFModal pdfSrc={pdf} open={open} pdfAlt={title} handleClose={handleClose}/>
        </div>
    )
}

export default CaseStudyCard;